:root {
    --color-femida: #590c9d;
    --color-femida-orange: #f7a014;
    --color-femida-orange-light: #fc0;
    --color-femida-red: #d00;
    --color-femida-green: #049657;
    --color-femida-blue: #425ec3;
    --color-femida-blue-light: #6ec5ef;
    --color-femida-gray: rgba(0, 0, 0, .7); /* непрозрачный #4c4c4c */
    --color-femida-gray-medium: #aeaeae;
    --color-femida-gray-light: #f6f5f3;
    --color-femida-gray-border: #e6e6e6;
    --color-femida-red-light: #f33;
    --color-femida-white: #fff;
    --color-femida-black: #000;
    --color-femida-form-warning: #f5a623;
    --color-femida-suggest-secondary: #aaa;
    /* 50 оттенков серого, но воспроизвожу внешнюю анкету дословно */
    --color-footer-border: #dedddb;
    --color-footer-link: #8792a8;
    --color-row-hint: #999;
    --color-restore-border: rgba(0, 0, 0, .1);

    --color-macros-border: rgba(0, 0, 0, .1);
    --color-marcos: #eee;
    --color-macros-input-border: rgba(0, 0, 0, .2);
    --color-tools-form-divider: #e5e5e5;

    --text-small: 12px;
    --text-smaller: 13px;
    --text-normal: 15px;
    --text-medium: 18px;
    --text-big: 24px;

    --text-heading-xxl: 32px;
    --text-line-height-xxl: 44px;
    --text-heading-xl: 24px;
    --text-line-height-xl: 32px;
    --text-heading-l: 20px;
    --text-line-height-l: 28px;
    --text-heading-m: 18px;
    --text-line-height-m: 24px;
    --text-heading-s: 15px;
    --text-line-height-s: 20px;
    --text-heading-xs: 13px;
    --text-line-height-xs: 16px;

    --text-xl-rem: 1.45rem;
    --text-xs-rem: 0.7rem;
    --text-s-rem: 0.9rem;

    --tools-form-border-radius: 6px;

    --gap-xxs: 4px;
    --gap-xs: 8px;
    --gap-m: 16px;
    --gap-xl: 24px;
    --gap-xxl: 28px;

    --screen-mobile: 320px;
    --screen-tablet: 768px;
    --screen-desktop: 1024px;
    --screen-wide: 1920px;
}

body {
    margin: 0;
}

.Root {
    display: flex;
    flex-direction: column;
}

.Root-Container {
    position: relative;
    z-index: 0;
    flex-grow: 1;
    padding-bottom: 32px;
}

.no-show {
    display: none;
}

.Error-Container {
    display: flex;
    flex-direction: column;
    align-content: space-around;
    align-items: center;
}

.Page {
    display: flex;
    position: relative;
    /* justify-content: space-between; */
    /* padding: 0 60px; */
}

.Page-Content {
    position: relative;
    z-index: 0;
    display: flex;
    padding-bottom: 32px;
    flex-direction: column;
    width: 75%;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 30px;
}

.Page_theme_grey .Page-Content {
    background-color: #f9f9f9;
}

#main-top-panel {
    width: 100%;
    height: 50px;
    padding: 0 24px;
    box-sizing: border-box;
}

.yandex-header__item.tools-header__search {
    display: block;
}

.tools-header__logo {
    width: 190px;
}

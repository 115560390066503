@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  overflow: hidden;
  position: relative;
}

iframe {
  pointer-events: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
}

.tools-menu__item_active_no {
  box-sizing: border-box;
  border-top: 3px solid transparent;
}

.tools-menu__item_active_yes {
  box-sizing: border-box;
  border-top: 3px solid #36b5ad;
}

.tools-menu__item {
  margin-right: 16px !important;
}

.tools-user-account__user-pic {
  border-radius: 0px !important;
  width: 50px !important;
  height: 50px !important;
}

.tools-header__content {
  padding: 0 !important;
}

.Work-Area {
  display: flex;
  flex-direction: column;
  grid-template-columns: 1fr 3fr;
  grid-gap: 2ch;
  width: 900px;
}

/* <Margin> */

.marged {
  margin: 0.6rem;
}

.marged-top {
  margin-top: 0.6rem;
}

.marged-right {
  margin-right: 0.6rem;
}

.marged-bottom {
  margin-bottom: 0.6rem;
}

.marged-left {
  margin-left: 0.6rem;
}

.marged-small {
  margin: 0.3rem;
}

.marged-top-small {
  margin-top: 0.3rem;
}

.marged-right-small {
  margin-right: 0.3rem;
}

.marged-bottom-small {
  margin-bottom: 0.3rem;
}

.marged-left-small {
  margin-left: 0.3rem;
}

.not-marged {
  margin: 0;
}

.a4 {
  max-width: 720px;
}

.a4 + .a4 {
  margin-top: 2ch;
}
/* </Margin> */

.tools-header__notifier {
  z-index: 1;
}

.pointer {
  cursor: pointer;
}

.color-lighter {
  color: #888;
}

.quill > .ql-container,
.quill {
  min-height: 200px;
  font-size: 20px;
  min-width: 100%;
}